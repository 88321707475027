export default {
    data() {
        return {
            pagination: {},
            featuredItem: {},
            mainItems: [],
            sideItems: [],
            loadMore: false
        }
    },

    computed: {
        firstPage() {
            return this.pagination.nextPage === 0;
        }
    },

    methods: {
        setPaginationDefaults() {
            this.pagination = {
                start: 0,
                length: 0,
                nextPage: 0
            }
        },

        requestData(uri, dataHandler, $state) {
            fetch(`${this.$bhrt.api_uri}/${uri}`)
                .then(response => response.json())
                .then(data => {
                    dataHandler(data);

                    if ($state) {
                        this.resolveLoader($state);
                    }
                })
        },

        resolveLoader($state) {
            if (!$state) return;

            if (this.loadMore) {
                $state.loaded();
            }else {
                $state.complete();
            }
        },

        emptyCollection() {
            this.mainItems = [];
            this.sideItems = [];
        },

        initData() {
            this.setPaginationDefaults();
            this.emptyCollection();
        },
    }
}